import React from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import styles from "../styles/styles";
import SidebarLink from "../components/SidebarLink";

const Terms = () => {
  const demoImages = [
    "https://media.istockphoto.com/id/1501103626/photo/defocused-background-image-of-a-spacious-hallway-in-a-modern-office.webp?b=1&s=170667a&w=0&k=20&c=3HUg5TdHHWq4rmYJ7lA0Jn9koAesfCrO4lFiEaUFKuI=",
    "https://www.maidwale.com/images/about/istockphoto-1283119095-170667a.jpg",
    "https://www.maidwale.com/images/about/istockphoto-1283119095-170667a.jpg",
  ];
  return (
    <>
      <Header activeHeading={0} navbar={true} />
      <div className="bg-[#E7F2F9]">
        {/* <div className="relative">
          <img
            src={`${demoImages[0]}`}
            alt="Demo Image 1"
            className="w-full h-auto rounded-md mb-6 h-[700px]"
          />
          <div className="absolute bottom-10 md:bottom-56 left-4 md:left-40 text-gray-100 text-5xl md:text-6xl font-Poppins font-bold">
            TERMS OF USE
            <div className="text-base md:text-xl font-medium">
              Learn what we are all about
            </div>
          </div>
        </div> */}

        <div className="container mx-auto py-8 flex flex-col md:flex-row">
          <div className=" max-w-4xl m-auto px-7 text-justify">
            <h1 className="text-3xl font-bold mb-4">TERMS OF USE</h1>
            <p className="mb-4">
              This document is an electronic record in terms of Information
              Technology Act, 2000 and rules there under as applicable and the
              amended provisions pertaining to electronic records in various
              statutes as amended by the Information Technology Act, 2000. This
              electronic record is generated by a computer system and does not
              require any physical or digital signatures.
            </p>
            <p>
              This document is published in accordance with the provisions of
              Rule 3 (1) of the Information Technology (Intermediaries
              guidelines) Rules, 2011 that require publishing the rules and
              regulations, privacy policy and Terms of Use for access or usage
              of careers.Modcub.in, life.mpdcub.com & blog.Modcub.in website.
            </p>
            <p>
              The use of any product, service or feature (the “Materials”)
              available through careers.Modcub.in, life.Modcub.in &
              blog.Modcub.in (hereinafter referred to as “Website”) is owned by
              Modcub Designs Private Limited, a company incorporated under the
              Companies Act, 1956 with its registered office at ground floor,
              <b>11752/4 Sat Nagar Karol Bagh, New Delhi, India </b>{" "}
              (hereinafter referred to as “Modcub”).
            </p>
            <p className="mb-4">
              For the purpose of these Terms of Use, wherever the context so
              requires “You” or “User” shall mean any natural or legal person
              who visits or uses the Website. Modcub allows the User to surf the
              Website without registering on the Website. The term “We”, “Us”,
              “Our” shall mean Modcub Designs Private Limited.
            </p>
            <p>
              Your use of the Website and services and tools are governed by the
              following terms and conditions (“Terms of Use”) as applicable to
              the Website including the applicable policies which are
              incorporated herein by way of reference. Use of the Website shall
              subject You to the policies that are applicable to the Website. By
              mere use of the Website, You shall be contracting with Modcub
              Designs Private Limited and these terms and conditions including
              the policies constitute Your binding obligations, with Modcub.
            </p>
            <p className="mb-4">
              This Website, including all Materials present (excluding any
              applicable third party materials), is the property of Modcub and
              is copyrighted and protected by worldwide copyright laws and
              treaty provisions. You hereby agree to comply with all copyright
              laws worldwide in Your use of this Website and to prevent any
              unauthorized copying of the Materials. Myntra does not grant any
              express or implied rights under any patents, trademarks,
              copyrights or trade secret information.
            </p>
            <p>
              When you use any Materials provided by us through the Website you
              will be subject to the rules, guidelines, policies, terms and
              conditions applicable to such services and they shall be deemed
              incorporated into the ToU and considered a part and parcel of the
              ToU. We reserve the right, at our sole discretion, to change,
              modify, add, or remove portions of the ToU at any time and without
              any prior written notice to you. It is your responsibility to
              review the ToU periodically for updates/changes. Your continued
              use of the Website following the changes will mean that you accept
              and agree to the revisions. As long as you comply with the ToU, we
              grant you a personal, non-exclusive, non-transferable, and limited
              privilege to enter and use the Website.
            </p>
            <p className="mb-4">
              ACCESSING, BROWSING OR OTHERWISE USING THE WEBSITE INDICATES YOUR
              AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF
              USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING.
              By impliedly or expressly accepting these Terms of Use, You also
              accept and agree to be bound by Modcub Policies (including but not
              limited to Privacy Policy available on Privacy Policy as amended
              from time to time.
            </p>
            <h2 className="text-xl font-bold mb-2">USER ELIGIBILITY</h2>
            <p>
              Use of the Website is available only to persons who can form
              legally binding contracts under Indian Contract Act, 1872. Persons
              who are “incompetent to contract” within the meaning of the Indian
              Contract Act, 1872 including minors, un-discharged insolvents etc.
              are not eligible to use the Website. If you are a minor i.e. under
              the age of 18 years, you shall not register as a User of the
              Website and shall not use the website. As a minor if you wish to
              use the website, such use may be made by your legal guardian or
              parents on the Website. Modcub reserves the right to terminate
              your membership and / or refuse to provide you with access to the
              Website if it is brought to Modcub’s notice or if it is discovered
              that you are under the age of 18 years.
            </p>
            <h2 className="text-xl font-bold mb-2">
              YOUR ACCOUNT AND REGISTRATION OBLIGATIONS
            </h2>
            <p>
              If You use the Website, You shall be responsible for maintaining
              the confidentiality of your Display Name and Password and You
              shall be responsible for all activities that occur under your
              Display Name and Password. You agree that if You provide any
              information that is untrue, inaccurate, not current or incomplete
              or We have reasonable grounds to suspect that such information is
              untrue, inaccurate, not current or incomplete, or not in
              accordance with this Terms of Use, We shall have the right to
              indefinitely suspend or terminate or block access of your
              membership on the Website and refuse to provide You with access to
              the Website.
            </p>
            {/* </div> */}
            <div>
              <h2 className="text-xl font-bold mb-2">COMMUNICATIONS</h2>
              <p>
                When You use the Website or send emails or other data,
                information or communication to us, You agree and understand
                that You are communicating with Us through electronic records
                and You consent to receive communications via electronic records
                from Us periodically and as and when required. We may
                communicate with you by email or by such other mode of
                communication, electronic or otherwise.
              </p>
              <h2 className="text-xl font-bold mb-2">CHARGES</h2>
              <p>
                Membership on the Website is free. Modcub does not charge any
                fee for browsing the Website. In particular, Modcub may at its
                sole discretion introduce new services and modify some or all of
                the existing services offered on the Website.
              </p>
            </div>
            <div>
              <h2 className="text-xl font-bold mb-2">Blogs</h2>
              <p>
                This is to inform and clarify that individuals (including but
                not limited to employees of Modcub and referred to as
                “Individuals” hereafter) may contribute articles and opinions on
                this Website entirely at the sole discretion of Modcub, in the
                form of “blogs”, as such term is generally understood. You
                hereby acknowledge and agree that these blogs constitute the
                opinion of the Individuals in their personal capacity, and may
                not represent official positions of Modcub in any manner. Modcub
                retains all ownership and rights in these blogs.
              </p>
              <p>
                You may be permitted to post comments and feedback to these
                blogs. By doing so, You expressly agree and acknowledge to abide
                by the following:
              </p>
              <ol className="list-decimal ml-8">
                <li>
                  You shall not host, display, upload, modify, publish,
                  transmit, update or share any information which:
                </li>
                <ol className="list-decimal ml-8">
                  <li>
                    belongs to another person and to which You do not have any
                    right;
                  </li>
                  <li>
                    is grossly harmful, harassing, blasphemous, defamatory,
                    obscene, pornographic, pedophilic, libelous, invasive of
                    another’s privacy, hateful, or racially, ethnically
                    objectionable, disparaging, relating or encouraging money
                    laundering or gambling, or otherwise unlawful in any manner
                    whatever; or unlawfully threatening or unlawfully harassing
                    including but not limited to “indecent representation of
                    women” within the meaning of the Indecent Representation of
                    Women (Prohibition) Act, 1986;
                  </li>
                  <li>is misleading in any way;</li>
                  <li>
                    is patently offensive to the online community, such as
                    sexually explicit content, or content that promotes
                    obscenity, pedophilia, racism, bigotry, hatred or physical
                    harm of any kind against any group or individual;
                  </li>
                  <li>harasses or advocates harassment of another person;</li>
                  <li>
                    involves the transmission of “junk mail”, “chain letters”,
                    or unsolicited mass mailing or “spamming”;
                  </li>
                  <li>
                    promotes illegal activities or conduct that is abusive,
                    threatening, obscene, defamatory or libelous;
                  </li>
                  <li>
                    infringes upon or violates any third party’s rights
                    [including, but not limited to, intellectual property
                    rights, rights of privacy (including without limitation
                    unauthorized disclosure of a person’s name, email address,
                    physical address or phone number) or rights of publicity];
                  </li>
                  <li>
                    promotes an illegal or unauthorized copy of another person’s
                    copyrighted work (see “Copyright complaint” below for
                    instructions on how to lodge a complaint about uploaded
                    copyrighted material), such as providing pirated computer
                    programs or links to them, providing information to
                    circumvent manufacture-installed copy-protect devices, or
                    providing pirated music or links to pirated music files;
                  </li>
                  <li>
                    contains restricted or password-only access pages, or hidden
                    pages or images (those not linked to or from another
                    accessible page);
                  </li>
                  <li>
                    provides material that exploits people in a sexual, violent
                    or otherwise inappropriate manner or solicits personal
                    information from anyone;
                  </li>
                  <li>
                    provides instructional information about illegal activities
                    such as making or buying illegal weapons, violating
                    someone’s privacy, or providing or creating computer
                    viruses;
                  </li>
                  <li>
                    contains video, photographs, or images of another person
                    (with a minor or an adult).
                  </li>
                </ol>
                <li>
                  tries to gain unauthorized access or exceeds the scope of
                  authorized access to the Website or to profiles, blogs,
                  communities, account information, bulletins, friend request,
                  or other areas of the Website or solicits passwords or
                  personal identifying information for commercial or unlawful
                  purposes from other users;
                </li>
                <li>
                  solicits gambling or engages in any gambling activity which
                  We, in Our sole discretion, believes is or could be construed
                  as being illegal;
                </li>
                <li>
                  interferes with another User’s use and enjoyment of the
                  Website or any other User’s enjoyment of similar services;
                </li>
                <li>
                  refers to any website or URL that, in Our sole discretion,
                  contains material that is inappropriate for the Website or any
                  other website, contains content that would be prohibited or
                  violates the letter or spirit of these Terms of Use.
                </li>
                <li>harm minors in any way;</li>
                <li>
                  infringes any patent, trademark, copyright or other
                  proprietary rights or third party’s trade secrets or rights of
                  publicity or privacy;
                </li>
                <li>violates any law for the time being in force;</li>
                <li>
                  deceives or misleads the addressee/ users about the origin of
                  such messages or communicates any information which is grossly
                  offensive or menacing in nature;
                </li>
                <li>impersonate another person;</li>
                <li>
                  contains software viruses or any other computer code, files or
                  programs designed to interrupt, destroy or limit the
                  functionality of any computer resource; or contains any trojan
                  horses, worms, time bombs, cancelbots, easter eggs or other
                  computer programming routines that may damage, detrimentally
                  interfere with, diminish value of, surreptitiously intercept
                  or expropriate any system, data or personal information;
                </li>
                <li>
                  threatens the unity, integrity, defence, security or
                  sovereignty of India, friendly relations with foreign states,
                  or public order or causes incitement to the commission of any
                  cognizable offence or prevents investigation of any offence or
                  is insulting any other nation.
                </li>
                <li>shall not be false, inaccurate or misleading;</li>
                <li>
                  shall not create liability for Us or cause Us to lose (in
                  whole or in part) the services of Our internet service
                  provider (“ISPs”) or other suppliers;
                </li>
                <li>
                  You shall not use any “deep-link”, “page-scrape”, “robot”,
                  “spider” or other automatic device, program, algorithm or
                  methodology, or any similar or equivalent manual process, to
                  access, acquire, copy or monitor any portion of the Website or
                  any Content, or in any way reproduce or circumvent the
                  navigational structure or presentation of the Website or any
                  Content, to obtain or attempt to obtain any materials,
                  documents or information through any means not purposely made
                  available through the Website. We reserve Our right to bar any
                  such activity.
                </li>
                <li>
                  You shall not attempt to gain unauthorized access to any
                  portion or feature of the Website, or any other systems or
                  networks connected to the Website or to any server, computer,
                  network, or to any of the services offered on or through the
                  Website, by hacking, password “mining” or any other
                  illegitimate means.
                </li>
                <li>
                  You shall not probe, scan or test the vulnerability of the
                  Website or any network connected to the Website nor breach the
                  security or authentication measures on the Website or any
                  network connected to the Website. You may not reverse look-up,
                  trace or seek to trace any information on any other User of or
                  visitor to Website, or any other customer, including any
                  account on the Website not owned by You, to its source, or
                  exploit the Website or any service or information made
                  available or offered by or through the Website, in any way
                  where the purpose is to reveal any information, including but
                  not limited to personal identification or information, other
                  than Your own information, as provided for by the Website.
                </li>
                <li>
                  You shall not make any negative, denigrating or defamatory
                  statement(s) or comment(s) about Us or the brand name or
                  domain name used by Us including the terms Modcub, Modcub.in,
                  or otherwise engage in any conduct or action that might
                  tarnish the image or reputation, of Modcub or otherwise
                  tarnish or dilute any Modcub’s trade or service marks, trade
                  name and/or goodwill associated with such trade or service
                  marks, trade name as may be owned or used by us. You agree
                  that You will not take any action that imposes an unreasonable
                  or disproportionately large load on the infrastructure of the
                  Website or Modcub’s systems or networks, or any systems or
                  networks connected to Modcub.
                </li>
                <li>
                  You agree not to use any device, software or routine to
                  interfere or attempt to interfere with the proper working of
                  the Website or any transaction being conducted on the Website,
                  or with any other person’s use of the Website.
                </li>
                <li>
                  You may not forge headers or otherwise manipulate identifiers
                  in order to disguise the origin of any message or transmittal
                  You send to Us on or through the Website or any service
                  offered on or through the Website. You may not pretend that
                  You are, or that You represent, someone else, or impersonate
                  any other individual or entity.
                </li>
                <li>
                  You may not use the Website or any Content for any purpose
                  that is unlawful or prohibited by these Terms of Use, or to
                  solicit the performance of any illegal activity or other
                  activity which infringes the rights of Modcub or others.
                </li>
                <li>
                  You shall at all times ensure full compliance with the
                  applicable provisions of the Information Technology Act, 2000
                  and rules thereunder as applicable and as amended from time to
                  time and also all applicable Domestic laws, rules and
                  regulations (including the provisions of any applicable
                  Exchange Control Laws or Regulations in Force) and
                  International Laws, Foreign Exchange Laws, Statutes,
                  Ordinances and Regulations (including, but not limited to
                  Sales Tax/VAT, Income Tax, Octroi, Service Tax, Central
                  Excise, Custom Duty, Local Levies) regarding Your use of Our
                  service and Your listing, purchase, solicitation of offers to
                  purchase, and sale of products or services. You shall not
                  engage in any transaction in an item or service, which is
                  prohibited by the provisions of any applicable law including
                  exchange control laws or regulations for the time being in
                  force.
                </li>
              </ol>
            </div>
            <div>
              <h2 className="text-xl font-bold mb-2">User Generated Content</h2>
              <p>
                You hereby grant Modcub a perpetual, non-revocable, worldwide,
                royalty-free license to use, copy, distribute, display,
                reproduce, modify, adapt, the User Generated Content submitted
                by You on the Website for providing services to You. Please note
                that this license continues even if You stop using the Website
                or services provided therein.
              </p>
              <p>
                You hereby represent and warrant that You own all rights, title
                and interest in and to User Generated Content submitted by You
                or have the necessary licenses, rights, consents, and
                permissions to grant the Company the license to the User
                Generated Content as set forth herein and that such User
                Generated Content does not infringe the intellectual property
                rights, privacy rights, publicity rights, or other legal rights
                of any third party.
              </p>
              <p>
                You agree to indemnify and keep indemnified Modcub, its
                partners, licensors, associates, subsidiaries, group companies
                (as applicable) and their respective officers, directors,
                agents, and employees, from any and all claims or demands, or
                actions including reasonable attorneys’ fees, made by any third
                party or penalty imposed due to or arising out of Your breach of
                this clause or Your violation of any law, rules or regulations
                or the rights of a third party.
              </p>
            </div>
            <div>
              <h2 className="text-xl font-bold mb-2">
                Limitation of Liability
              </h2>
              <p>
                In no event shall Modcub or any of its subsidiaries or
                affiliates be liable to any entity for any direct, indirect,
                special, consequential or other damages (including, without
                limitation, any lost profits, business interruption, loss of
                information or programs or other data on your information
                handling system) that are related to the use of, or the
                inability to use, the content, materials, and functions of this
                Website or any linked Website, even if Modcub is expressly
                advised of the possibility of such damages.
              </p>
            </div>
            <div>
              <h2 className="text-xl font-bold mb-2">Disclaimer</h2>
              <p>
                The Website may contain inaccuracies and typographical and
                clerical errors. Modcub expressly disclaims any obligation(s) to
                update this Website or any of the materials on this website.
                Modcub does not warrant the accuracy or completeness of the
                Materials or the reliability of any advice, opinion, statement
                or other information displayed or distributed through the
                Website. You acknowledge that any reliance on any such opinion,
                advice, statement, memorandum, or information shall be at your
                sole risk. Myntra reserves the right, in its sole discretion, to
                correct any errors or omissions in any portion of the Website.
                Myntra may make any other changes to the Website, the materials
                and the products, programs, services or prices (if any)
                described in the Website at any time without notice. This
                Website is for informational purposes only and should not be
                construed as technical advice of any manner.
              </p>
            </div>
          </div>
          <div className="w-1/4 ml-8 max-1000px:hidden">
            <SidebarLink />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Terms;

import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
    LoginPage,
    SignupPage,
    ActivationPage,
    HomePage,
    ProductsPage,
    BestSellingPage,
    EventsPage,
    FAQPage,
    CheckoutPage,
    PaymentPage,
    OrderSuccessPage,
    ProductDetailsPage,
    ProfilePage,
    ShopCreatePage,
    SellerActivationPage,
    ShopLoginPage,
    OrderDetailsPage,
    TrackOrderPage,
    UserInbox,
    ForgetPage,
    ResetPassword,
} from "./routes/Routes.js";
import {
    ShopDashboardPage,
    ShopCreateProduct,
    ShopAllProducts,
    ShopCreateEvents,
    ShopAllEvents,
    ShopAllCoupouns,
    ShopPreviewPage,
    ShopAllOrders,
    ShopOrderDetails,
    ShopAllRefunds,
    ShopSettingsPage,
    ShopWithDrawMoneyPage,
    ShopInboxPage,
    ShopDashboardRenew,
} from "./routes/ShopRoutes";
import {
    AdminDashboardPage,
    AdminDashboardUsers,
    AdminDashboardSellers,
    AdminDashboardOrders,
    AdminDashboardProducts,
    AdminDashboardEvents,
    AdminDashboardWithdraw,
} from "./routes/AdminRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Store from "./redux/store";
import { loadSeller, loadUser } from "./redux/actions/user";
import ProtectedRoute from "./routes/ProtectedRoute";
import ProtectedAdminRoute from "./routes/ProtectedAdminRoute";
import { ShopHomePage } from "./ShopRoutes.js";
import SellerProtectedRoute from "./routes/SellerProtectedRoute";
import { getAllProducts } from "./redux/actions/product";
import { getAllEvents } from "./redux/actions/event";
import axios from "axios";
import { server } from "./server";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import AdminDashboardAdmin from "./pages/AdminDashboardAdmin.jsx";
import AdminDashboardApproveProducts from "./pages/AdminDashboardApproveProducts.jsx";

import About from "./pages/About";
import ShopSuspend from "./pages/Shop/ShopSuspend";
import Privacy from "./pages/Privacy.jsx";
import Terms from "./pages/Terms.jsx";
import ContactUs from "./pages/ContactUs.jsx";
import StoreLocation from "./pages/StoreLocation.jsx";
import Corporate from "./pages/Corporate.jsx";
import BrandCollaborate from "./pages/BrandCollaborate.jsx";
import OurBlogs from "./pages/OurBlogs.jsx";
import Review from "./pages/Review.jsx";
import Carrers from "./pages/Carrers.jsx";
import Help from "./pages/Help.jsx";
import ReturCenter from "./pages/ReturnCenter.jsx";
import ShopEditProduct from "./pages/Shop/ShopEditProduct.jsx";
import AdminContactList from "./pages/AdminContactList.js";
import ShipingandDelivery from "./pages/ShipingandDelivery.jsx";
import SiteMap from "./pages/SiteMap.jsx";

const App = () => {
    const [stripeApikey, setStripeApiKey] = useState("");

    async function getStripeApikey() {
        const { data } = await axios.get(`${server}/payment/stripeapikey`);
        setStripeApiKey(data.stripeApikey);
    }
    useEffect(() => {
        Store.dispatch(loadUser());
        Store.dispatch(loadSeller());
        Store.dispatch(getAllProducts());
        Store.dispatch(getAllEvents());
        getStripeApikey();
    }, []);

    return (
        <BrowserRouter>
            {stripeApikey && (
                <Elements stripe={loadStripe(stripeApikey)}>
                    <Routes>
                        <Route
                            path="/payment"
                            element={
                                <ProtectedRoute>
                                    <PaymentPage />
                                </ProtectedRoute>
                            }
                        />
                    </Routes>
                </Elements>
            )}
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/forgot-password" element={<ForgetPage />} />
                <Route
                    path="/reset-password/:token"
                    element={<ResetPassword />}
                />

                <Route path="/sign-up" element={<SignupPage />} />
                <Route path="/about" element={<About />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/cancelationandrefund" element={<Carrers />} />
                <Route path="/help" element={<Help />} />
                <Route path="/store-location" element={<StoreLocation />} />
                <Route path="/corporate" element={<Corporate />} />
                <Route
                    path="/brand-collaborate"
                    element={<BrandCollaborate />}
                />
                <Route path="/our-blogs" element={<OurBlogs />} />
                <Route path="/review" element={<Review />} />
                <Route path="/shippinganddelivery" element={<ShipingandDelivery />} />
                <Route path="/site-map" element={<SiteMap />} />
                <Route path="/return-center" element={<ReturCenter />} />

                <Route
                    path="/activation/:activation_token"
                    element={<ActivationPage />}
                />
                <Route
                    path="/seller/activation/:activation_token"
                    element={<SellerActivationPage />}
                />
                <Route path="/products" element={<ProductsPage />} />
                <Route path="/product/:id" element={<ProductDetailsPage />} />
                <Route path="/best-selling" element={<BestSellingPage />} />
                <Route path="/events" element={<EventsPage />} />
                <Route path="/faq" element={<FAQPage />} />
                <Route
                    path="/checkout"
                    element={
                        <ProtectedRoute>
                            <CheckoutPage />
                        </ProtectedRoute>
                    }
                />
                <Route path="/order/success" element={<OrderSuccessPage />} />
                <Route
                    path="/profile"
                    element={
                        <ProtectedRoute>
                            <ProfilePage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/inbox"
                    element={
                        <ProtectedRoute>
                            <UserInbox />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/user/order/:id"
                    element={
                        <ProtectedRoute>
                            <OrderDetailsPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/user/track/order/:id"
                    element={
                        <ProtectedRoute>
                            <TrackOrderPage />
                        </ProtectedRoute>
                    }
                />
                <Route path="/shop/preview/:id" element={<ShopPreviewPage />} />
                {/* shop Routes */}
                <Route path="/shop-create" element={<ShopCreatePage />} />
                <Route path="/shop-login" element={<ShopLoginPage />} />
                <Route
                    path="/shop/:id"
                    element={
                        <SellerProtectedRoute>
                            <ShopHomePage />
                        </SellerProtectedRoute>
                    }
                />
                <Route
                    path="/settings"
                    element={
                        <SellerProtectedRoute>
                            <ShopSettingsPage />
                        </SellerProtectedRoute>
                    }
                />
                <Route
                    path="/dashboard"
                    element={
                        <SellerProtectedRoute>
                            <ShopDashboardPage />
                        </SellerProtectedRoute>
                    }
                />

                <Route
                    path="/suspend"
                    element={
                        // <SellerProtectedRoute>
                        <ShopSuspend />
                        // </SellerProtectedRoute>
                    }
                />
                <Route
                    path="/dashboard-renew"
                    element={
                        <>
                            <ShopDashboardRenew />
                        </>
                    }
                />
                <Route
                    path="/dashboard-create-product"
                    element={
                        <SellerProtectedRoute>
                            <ShopCreateProduct />
                        </SellerProtectedRoute>
                    }
                />
                <Route
                    path="/dashboard-orders"
                    element={
                        <SellerProtectedRoute>
                            <ShopAllOrders />
                        </SellerProtectedRoute>
                    }
                />
                <Route
                    path="/dashboard-refunds"
                    element={
                        <SellerProtectedRoute>
                            <ShopAllRefunds />
                        </SellerProtectedRoute>
                    }
                />

                <Route
                    path="/order/:id"
                    element={
                        <SellerProtectedRoute>
                            <ShopOrderDetails />
                        </SellerProtectedRoute>
                    }
                />
                <Route
                    path="/dashboard-products"
                    element={
                        <SellerProtectedRoute>
                            <ShopAllProducts />
                        </SellerProtectedRoute>
                    }
                />
                 <Route
                    path="/dashboard-update-product/:id"
                    element={
                        <SellerProtectedRoute>
                            <ShopEditProduct/>
                        </SellerProtectedRoute>
                    }
                />
                <Route
                    path="/dashboard-create-event"
                    element={
                        <SellerProtectedRoute>
                            <ShopCreateEvents />
                        </SellerProtectedRoute>
                    }
                />
                <Route
                    path="/dashboard-events"
                    element={
                        <SellerProtectedRoute>
                            <ShopAllEvents />
                        </SellerProtectedRoute>
                    }
                />
                <Route
                    path="/dashboard-coupouns"
                    element={
                        <SellerProtectedRoute>
                            <ShopAllCoupouns />
                        </SellerProtectedRoute>
                    }
                />
                <Route
                    path="/dashboard-withdraw-money"
                    element={
                        <SellerProtectedRoute>
                            <ShopWithDrawMoneyPage />
                        </SellerProtectedRoute>
                    }
                />
                <Route
                    path="/dashboard-messages"
                    element={
                        <SellerProtectedRoute>
                            <ShopInboxPage />
                        </SellerProtectedRoute>
                    }
                />
                {/* Admin Routes */}

                <Route
                    path="/admin-users"
                    element={
                        <ProtectedAdminRoute>
                            <AdminDashboardUsers />
                        </ProtectedAdminRoute>
                    }
                />
                <Route
                    path="/admin/dashboard"
                    element={
                        <ProtectedAdminRoute>
                            <AdminDashboardPage />
                        </ProtectedAdminRoute>
                    }
                />
                <Route
                    path="/admin-approve-products"
                    element={
                        <ProtectedAdminRoute>
                            <AdminDashboardApproveProducts />
                        </ProtectedAdminRoute>
                    }
                />
                <Route
                    path="/admin-sellers"
                    element={
                        <ProtectedAdminRoute>
                            <AdminDashboardSellers />
                        </ProtectedAdminRoute>
                    }
                />
                <Route
                    path="/admin-orders"
                    element={
                        <ProtectedAdminRoute>
                            <AdminDashboardOrders />
                        </ProtectedAdminRoute>
                    }
                />
                <Route
                    path="/admin-products"
                    element={
                        <ProtectedAdminRoute>
                            <AdminDashboardProducts />
                        </ProtectedAdminRoute>
                    }
                />
                <Route
                    path="/contact-list"
                    element={
                        <ProtectedAdminRoute>
                            <AdminContactList />
                        </ProtectedAdminRoute>
                    }
                />
                <Route
                    path="/admin-events"
                    element={
                        <ProtectedAdminRoute>
                            <AdminDashboardEvents />
                        </ProtectedAdminRoute>
                    }
                />
                <Route
                    path="/admin-withdraw-request"
                    element={
                        <ProtectedAdminRoute>
                            <AdminDashboardWithdraw />
                        </ProtectedAdminRoute>
                    }
                />
                <Route
                    path="/admin-admins"
                    element={
                        <ProtectedAdminRoute>
                            <AdminDashboardAdmin />
                        </ProtectedAdminRoute>
                    }
                />
            </Routes>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </BrowserRouter>
    );
};

export default App;

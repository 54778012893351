import React from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import styles from "../styles/styles";
import SidebarLink from "../components/SidebarLink";

const Privacy = () => {
  // Placeholder data until backend integration
  const demoImages = [
    "https://media.istockphoto.com/id/1501103626/photo/defocused-background-image-of-a-spacious-hallway-in-a-modern-office.webp?b=1&s=170667a&w=0&k=20&c=3HUg5TdHHWq4rmYJ7lA0Jn9koAesfCrO4lFiEaUFKuI=",
    "https://www.maidwale.com/images/about/istockphoto-1283119095-170667a.jpg",
    "https://www.maidwale.com/images/about/istockphoto-1283119095-170667a.jpg",
  ];
  return (
    <>
      <Header navbar={true} />
      <div className="bg-[#E7F2F9]">
        <div className="container mx-auto py-8  flex flex-col md:flex-row">
          <div className="max-w-4xl mx-auto px-4 text-justify">
            <h1 className="text-3xl font-bold mb-4 text-black">
              Privacy Policy
            </h1>
            <p className="mb-4">
              We value the trust you place in us. That’s why we insist upon the
              highest standards for secure transactions and customer information
              privacy. Please read the following statement to learn about our
              information gathering and dissemination practices.
            </p>
            <p className="mb-4">
              <strong>Note:</strong> Our privacy policy is subject to change at
              any time without notice. To make sure you are aware of any
              changes, please review this policy periodically. By visiting our
              website, you agree to be bound by the terms and conditions of this
              Privacy Policy. If you do not agree, please do not use or access
              our website. By mere use of the website, you expressly consent to
              our use and disclosure of your personal information in accordance
              with this Privacy Policy. This Privacy Policy is incorporated into
              and subject to the Terms of Use.
            </p>
            <h2 className="text-xl font-bold mb-2 text-gray-700">
              1. Collection of Personally Identifiable Information and other
              Information
            </h2>
            <p className="mb-4">
              When you use our website, we collect and store your personal
              information which is provided by you from time to time. Our
              primary goal in doing so is to provide you a safe, efficient,
              smooth and customized experience. This allows us to provide
              services and features that most likely meet your needs, and to
              customize our website to make your experience safer and easier.
            </p>
            <p className="mb-4">
              <strong>
                More importantly, while doing so we collect personal information
                from you that we consider necessary for achieving this purpose.
                In general, you can browse the website without telling us who
                you are or revealing any personal information about yourself.
                Once you give us your personal information, you are not
                anonymous to us. Where possible, we indicate which fields are
                required and which fields are optional. You always have the
                option to not provide information by choosing not to use a
                particular service or feature on the website.
              </strong>
            </p>
            <h2 className="text-xl font-bold mb-2">
              2. Use of Demographic / Profile Data / Your Information
            </h2>
            <p className="mb-4">
              We use personal information to provide the services you request.
              To the extent we use your personal information to market to you,
              we will provide you the ability to opt-out of such uses. We use
              your personal information to resolve disputes; troubleshoot
              problems; help promote a safe service; collect money; measure
              consumer interest in our products and services, inform you about
              online and offline offers, products, services, and updates;
              customize your experience; detect and protect us against error,
              fraud and other criminal activity; enforce our terms and
              conditions; and as otherwise described to you at the time of
              collection.
            </p>
            <p className="mb-4">
              <strong>
                In our efforts to continually improve our product and service
                offerings, we collect and analyze demographic and profile data
                about our users’ activity on our website. We identify and use
                your IP address to help diagnose problems with our server, and
                to administer our website. Your IP address is also used to help
                identify you and to gather broad demographic information.
              </strong>
            </p>
            <h2 className="text-xl font-bold mb-2">
              3. Sharing of personal information
            </h2>
            <p className="mb-4">
              We may share personal information with our other corporate
              entities and affiliates. These entities and affiliates may market
              to you as a result of such sharing unless you explicitly opt-out.
              We may disclose personal information to third parties. This
              disclosure may be required for us to provide you access to our
              Services, to comply with our legal obligations, to enforce our
              User Agreement, to facilitate our marketing and advertising
              activities, or to prevent, detect, mitigate, and investigate
              fraudulent or illegal activities related to our Services. We do
              not disclose your personal information to third parties for their
              marketing and advertising purposes without your explicit consent.
              We may disclose personal information if required to do so by law
              or in the good faith belief that such disclosure is reasonably
              necessary to respond to subpoenas, court orders, or other legal
              process. We may disclose personal information to law enforcement
              offices, third party rights owners, or others in the good faith
              belief that such disclosure is reasonably necessary to: enforce
              our Terms or Privacy Policy; respond to claims that an
              advertisement, posting or other content violates the rights of a
              third party; or protect the rights, property or personal safety of
              our users or the general public. We and our affiliates will share
              / sell some or all of your personal information with another
              business entity should we (or our assets) plan to merge with, or
              be acquired by that business entity, or re-organization,
              amalgamation, restructuring of business. Should such a transaction
              occur that other business entity (or the new combined entity) will
              be required to follow this privacy policy with respect to your
              personal information.
            </p>

            {/* Additional Points */}
            <h2 className="text-xl font-bold mb-2">
              4. Links to Other Sites/Apps
            </h2>
            <p className="mb-4">
              Our website links to other websites/Apps that may collect
              personally identifiable information about you. Mntra is not
              responsible for the privacy practices or the content of those
              linked websites/Apps.
            </p>

            <h2 className="text-xl font-bold mb-2">
              5. Security Precautions
            </h2>
            <p className="mb-4">
              Our website has stringent security measures in place to protect
              the loss, misuse, and alteration of the information under our
              control. Whenever you change or access your account information,
              we offer the use of a secure server. Once your information is in
              our possession we adhere to strict security guidelines, protecting
              it against unauthorized access.
            </p>

            <h2 className="text-xl font-bold mb-2">
              6. Choice/Opt-Out
            </h2>
            <p className="mb-4">
              We provide all users with the opportunity to opt-out of receiving
              non-essential (promotional, marketing-related) communications from
              us on behalf of our partners, and from us in general, after
              setting up an account. If you want to remove your contact
              information from all our lists and newsletters, please visit
              unsubscribe.
            </p>

            <h2 className="text-xl font-bold mb-2">
              7. Advertisements on the Website
            </h2>
            <p className="mb-4">
              We may use third-party advertising companies to serve ads when you
              visit our website. These companies may use information (not
              including your name, address, email address, or telephone number)
              about your visits to this and other websites/Apps in order to
              provide advertisements about goods and services of interest to
              you.
            </p>

            <h2 className="text-xl font-bold mb-2">
              8. Your Consent
            </h2>
            <p className="mb-4">
              By using the website or by providing your information, you consent
              to the collection and use of the information you disclose on the
              website in accordance with this Privacy Policy, including but not
              limited to Your consent for sharing your information as per this
              privacy policy. If we decide to change our privacy policy, we will
              post those changes on this page so that you are always aware of
              what information we collect, how we use it, and under what
              circumstances we disclose it.
            </p>
            {/* Add more sections with similar formatting */}
            {/* You can format the content using appropriate HTML tags */}
            <h2 className="text-xl font-bold mb-2">
              9. Grievance Officer
            </h2>
            <p className="mb-4 ">
              In accordance with Information Technology Act 2000 and rules made
              there under, the name and contact details of the Grievance Officer
              are provided below:
            </p>
            <p className="mb-4">
              Mr. Viral production studios Pvt Ltd. ground floor, Sat Nagar
              Karol Bagh, New Delhi- Email: viralproductionstudios@gmail.com
              Time: Mon – Sat (9:00 – 18:00)
            </p>
            <h2 className="text-xl font-bold mb-2">
              10. Questions?
            </h2>
            <p className="mb-4">
              Please contact us regarding any questions regarding this
              statement.
            </p>
          </div>

          <div className="w-1/4 ml-8 max-1000px:hidden">
            <SidebarLink />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Privacy;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllAdmins } from "../../redux/actions/user";
import { DataGrid } from "@material-ui/data-grid";
import { AiOutlineDelete } from "react-icons/ai";
import { Button, Input } from "@material-ui/core";
import styles from "../../styles/styles";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";

const AllAdmins = () => {
    const dispatch = useDispatch();
    const { admins, user } = useSelector((state) => state.user);
    const [open, setOpen] = useState(false);
    const [openNew, setOpenNew] = useState(false);
    const [newAdminEmail, setNewAdminEmail] = useState("");

    const [userId, setUserId] = useState("");

    useEffect(() => {
        dispatch(getAllAdmins());
    }, [dispatch]);

    const handleDelete = async (id) => {
        await axios
            .put(
                `${server}/user/remove-admin`,
                { id: id },
                {
                    withCredentials: true,
                }
            )
            .then((res) => {
                toast.success(res.data.message);
            });

        dispatch(getAllAdmins());
    };
    const handleNewAdmin = async () => {
        await axios
            .put(
                `${server}/user/new-admin`,
                { email: newAdminEmail },
                {
                    withCredentials: true,
                }
            )
            .then((res) => {
                toast.success(res.data.message);
                dispatch(getAllAdmins());
            })
            .catch((error) => {
                toast.error("user not exist");
            });

        dispatch(getAllAdmins());
    };

    const columns = [
        { field: "id", headerName: "User ID", minWidth: 150, flex: 0.7 },

        {
            field: "name",
            headerName: "name",
            minWidth: 130,
            flex: 0.7,
        },
        {
            field: "email",
            headerName: "Email",
            type: "text",
            minWidth: 130,
            flex: 0.7,
        },
        {
            field: "role",
            headerName: "User Role",
            type: "text",
            minWidth: 130,
            flex: 0.7,
        },

        {
            field: "joinedAt",
            headerName: "joinedAt",
            type: "text",
            minWidth: 130,
            flex: 0.8,
        },

        {
            field: " ",
            flex: 1,
            minWidth: 150,
            headerName: "Delete User",
            type: "number",
            sortable: false,
            renderCell: (params) => {
                const { role, id } = params.row;
                const isSuperAdmin = user.role === "SuperAdmin";

                if (isSuperAdmin) {
                    return (
                        <>
                            <Button
                                onClick={() => setUserId(id) || setOpen(true)}
                            >
                                <AiOutlineDelete size={20} />
                            </Button>
                        </>
                    );
                }
            },
        },
    ];

    const row = [];
    admins &&
        admins.forEach((item) => {
            row.push({
                id: item._id,
                name: item.name,
                email: item.email,
                role: item.role,
                joinedAt: item.createdAt.slice(0, 10),
            });
        });

    return (
        <div className="w-full flex justify-center pt-5">
            <div className="w-[97%]">
                <h3 className="text-[22px] font-Poppins pb-2">All Admins</h3>
                <Button onClick={() => setOpenNew(true)}>
                    {" "}
                    Create new Admin
                </Button>
                {openNew && (
                    <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
                        <div className="w-[95%] 800px:w-[40%] min-h-[20vh] bg-white rounded shadow p-5">
                            <div className="w-full flex justify-end cursor-pointer">
                                <RxCross1
                                    size={25}
                                    onClick={() => setOpenNew(false)}
                                />
                            </div>
                            <h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
                                Are you sure you wanna make this user to Admin?
                            </h3>
                            <input
                                type="email"
                                name="email"
                                autoComplete="email"
                                required
                                value={newAdminEmail}
                                onChange={(e) =>
                                    setNewAdminEmail(e.target.value)
                                }
                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            />

                            <div className="w-full flex items-center justify-center">
                                <div
                                    className={`${styles.button} text-white text-[18px] !h-[42px] mr-4`}
                                    onClick={() => setOpenNew(false)}
                                >
                                    cancel
                                </div>
                                <div
                                    className={`${styles.button} text-white text-[18px] !h-[42px] ml-4`}
                                    onClick={() =>
                                        setOpenNew(false) || handleNewAdmin()
                                    }
                                >
                                    confirm
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="w-full min-h-[45vh] bg-white rounded">
                    <DataGrid
                        rows={row}
                        columns={columns}
                        pageSize={10}
                        disableSelectionOnClick
                        autoHeight
                    />
                </div>
                {open && (
                    <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
                        <div className="w-[95%] 800px:w-[40%] min-h-[20vh] bg-white rounded shadow p-5">
                            <div className="w-full flex justify-end cursor-pointer">
                                <RxCross1
                                    size={25}
                                    onClick={() => setOpen(false)}
                                />
                            </div>
                            <h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
                                Are you sure you wanna delete this user?
                            </h3>
                            <div className="w-full flex items-center justify-center">
                                <div
                                    className={`${styles.button} text-white text-[18px] !h-[42px] mr-4`}
                                    onClick={() => setOpen(false)}
                                >
                                    cancel
                                </div>
                                <div
                                    className={`${styles.button} text-white text-[18px] !h-[42px] ml-4`}
                                    onClick={() =>
                                        setOpen(false) || handleDelete(userId)
                                    }
                                >
                                    confirm
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AllAdmins;

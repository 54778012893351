import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import styles from "../../styles/styles";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { loadSeller } from "../../redux/actions/user";
import { AiOutlineDelete } from "react-icons/ai";

const WithdrawMoney = () => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const { seller } = useSelector((state) => state.seller);
    const [paymentMethod, setPaymentMethod] = useState(false);
    const [withdrawAmount, setWithdrawAmount] = useState(50);
    const [bankInfo, setBankInfo] = useState({
        IFSC: "",
        bankCountry: "",
        bankAccountNumber: null,
        bankHolderName: "",
    });

    useEffect(() => {
        dispatch(getAllOrdersOfShop(seller._id));
    }, [dispatch]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            const response =await axios.get(`https://ifsc.razorpay.com/${bankInfo.IFSC}`);
            const userDetails=response.data;
            if(!userDetails){
                toast.error("Please Input IFSC Code")
                return
            }

            const withdrawMethod = {
                bankCountry: bankInfo.bankCountry,
                bankAccountNumber: bankInfo.bankAccountNumber,
                bankHolderName: bankInfo.bankHolderName,
                ...userDetails,
            };
            setPaymentMethod(false);
            await axios
            .put(
                `${server}/shop/update-payment-methods`,
                {
                    withdrawMethod,
                },
                { withCredentials: true }
            )
            .then((res) => {
                toast.success("Withdraw method added successfully!");
                dispatch(loadSeller());
                setBankInfo({
                    bankName: "",
                    bankCountry: "",
                    bankSwiftCode: null,
                    bankAccountNumber: null,
                    bankHolderName: "",
                    bankAddress: "",
                });
            })
            .catch((error) => {
                console.log(error.response.data.message);
            });

        }catch(error){
            console.log("Error fetching bank details:", error);
            toast.error("Error fetching bank details. Please Check IFSC Code.");
        }


    };

    const deleteHandler = async () => {
        await axios
            .delete(`${server}/shop/delete-withdraw-method`, {
                withCredentials: true,
            })
            .then((res) => {
                toast.success("Withdraw method deleted successfully!");
                dispatch(loadSeller());
            });
    };

    const error = () => {
        toast.error("You not have enough balance to withdraw!");
    };

    const withdrawHandler = async () => {
        if (withdrawAmount < 50 || withdrawAmount > availableBalance) {
            toast.error("You can't withdraw this amount!");
        } else {
            const amount = withdrawAmount;
            await axios
                .post(
                    `${server}/withdraw/create-withdraw-request`,
                    { amount },
                    { withCredentials: true }
                )
                .then((res) => {
                    toast.success("Withdraw money request is successful!");
                });
        }
    };

    const availableBalance = seller?.availableBalance.toFixed(2);

    return (
        <div className="w-full h-[90vh] p-8">
             <h1 className="text-[44px] font-Poppins pb-2 font-semibold">
            Withdraw Money
          </h1>
            <div className="w-full bg-white h-full rounded flex items-center justify-center flex-col">

                <h5 className="text-[20px] pb-4">
                    Available Balance: Rs {availableBalance}
                </h5>
                <div
                    className={`${styles.button} text-white !h-[42px] !rounded`}
                    onClick={() =>
                        availableBalance < 50 ? error() : setOpen(true)
                    }
                >
                    Withdraw
                </div>
            </div>
            {open && (
                <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
                    <div
                        className={`w-[95%] 800px:w-[50%] bg-white shadow rounded ${
                            paymentMethod
                                ? "h-[80vh] overflow-y-scroll"
                                : "h-[unset]"
                        } min-h-[40vh] p-3`}
                    >
                        <div className="w-full flex justify-end">
                            <RxCross1
                                size={25}
                                onClick={() =>
                                    setOpen(false) || setPaymentMethod(false)
                                }
                                className="cursor-pointer"
                            />
                        </div>
                        {paymentMethod ? (
                            <div>
                                <h3 className="text-[22px] font-Poppins text-center font-[600]">
                                    Add new Withdraw Method:
                                </h3>
                                <form onSubmit={handleSubmit}>
                                    <div>
                                        <label>
                                            IFSC
                                            <span className="text-red-500">
                                                *
                                            </span>
                                        </label>
                                        <input
                                            type="text"
                                            name=""
                                            required
                                            value={bankInfo.IFSC}
                                            onChange={(e) =>
                                                setBankInfo({
                                                    ...bankInfo,
                                                    IFSC: e.target.value,
                                                })
                                            }
                                            id=""
                                            placeholder="Enter your IFSC Code! "
                                            className={`${styles.input} mt-2`}
                                        />
                                    </div>
                                    <div className="pt-2">
                                        <label>
                                            Bank Country{" "}
                                            <span className="text-red-500">
                                                *
                                            </span>
                                        </label>
                                        <input
                                            type="text"
                                            name=""
                                            value={bankInfo.bankCountry}
                                            onChange={(e) =>
                                                setBankInfo({
                                                    ...bankInfo,
                                                    bankCountry: e.target.value,
                                                })
                                            }
                                            id=""
                                            defaultValue={"INDIA"}
                                            placeholder="Enter your bank Country!"
                                            className={`${styles.input} mt-2`}
                                        />
                                    </div>

                                    <div className="pt-2">
                                        <label>
                                            Bank Account Number{" "}
                                            <span className="text-red-500">
                                                *
                                            </span>
                                        </label>
                                        <input
                                            type="number"
                                            name=""
                                            id=""
                                            value={bankInfo.bankAccountNumber}
                                            onChange={(e) =>
                                                setBankInfo({
                                                    ...bankInfo,
                                                    bankAccountNumber:
                                                        e.target.value,
                                                })
                                            }
                                            required
                                            placeholder="Enter your bank account number!"
                                            className={`${styles.input} mt-2`}
                                        />
                                    </div>
                                    <div className="pt-2">
                                        <label>
                                            Bank Holder Name{" "}
                                            <span className="text-red-500">
                                                *
                                            </span>
                                        </label>
                                        <input
                                            type="text"
                                            name=""
                                            required
                                            value={bankInfo.bankHolderName}
                                            onChange={(e) =>
                                                setBankInfo({
                                                    ...bankInfo,
                                                    bankHolderName:
                                                        e.target.value,
                                                })
                                            }
                                            id=""
                                            placeholder="Enter your bank Holder name!"
                                            className={`${styles.input} mt-2`}
                                        />
                                    </div>

                                    <button
                                        type="submit"
                                        className={`${styles.button} mb-3 text-white`}
                                    >
                                        Add
                                    </button>
                                </form>
                            </div>
                        ) : (
                            <>
                                <h3 className="text-[22px] font-Poppins">
                                    Available Withdraw Methods:
                                </h3>

                                {seller && seller?.withdrawMethod ? (
                                    <div>
                                        <div className="800px:flex w-full justify-between items-center">
                                            <div className="800px:w-[50%]">
                                                <h5>
                                                    Account Number:{" "}
                                                    {"*".repeat(
                                                        seller?.withdrawMethod
                                                            .bankAccountNumber
                                                            .length - 3
                                                    ) +
                                                        seller?.withdrawMethod.bankAccountNumber.slice(
                                                            -3
                                                        )}
                                                </h5>
                                                <h5>
                                                    Bank Name:{" "}
                                                    {
                                                        seller?.withdrawMethod
                                                            .BANK
                                                    }
                                                </h5>
                                            </div>
                                            <div className="800px:w-[50%]">
                                                <AiOutlineDelete
                                                    size={25}
                                                    className="cursor-pointer"
                                                    onClick={() =>
                                                        deleteHandler()
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <br />
                                        <h4>
                                            Available Balance:{" "}
                                            {availableBalance}$
                                        </h4>
                                        <br />
                                        <div className="800px:flex w-full items-center">
                                            <input
                                                type="number"
                                                placeholder="Amount..."
                                                value={withdrawAmount}
                                                onChange={(e) =>
                                                    setWithdrawAmount(
                                                        e.target.value
                                                    )
                                                }
                                                className="800px:w-[100px] w-[full] border 800px:mr-3 p-1 rounded"
                                            />
                                            <div
                                                className={`${styles.button} !h-[42px] text-white`}
                                                onClick={withdrawHandler}
                                            >
                                                Withdraw
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <p className="text-[18px] pt-2">
                                            No Withdraw Methods available!
                                        </p>
                                        <div className="w-full flex items-center">
                                            <div
                                                className={`${styles.button} text-[#fff] text-[18px] mt-4`}
                                                onClick={() =>
                                                    setPaymentMethod(true)
                                                }
                                            >
                                                Add new
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default WithdrawMoney;

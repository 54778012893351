import React from "react";
import { AiOutlineGift } from "react-icons/ai";
import { MdOutlineLocalOffer } from "react-icons/md";
import { FiPackage, FiShoppingBag } from "react-icons/fi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BiMessageSquareDetail } from "react-icons/bi";
import { Button } from "@material-ui/core";
import modcub from "../../../Assests/new_modcub.png";

const DashboardHeader = () => {
    const { seller } = useSelector((state) => state.seller);
    return (
        <div className="w-full h-[80px] bg-[#E7F2F9] shadow sticky top-0 left-0 z-30 flex items-center justify-between px-4">
            <div>
                <Link to="/dashboard">
                    <img
                        src={modcub}
                        width={150}
                        height={50}
                        style={{ "object-fit": "contain" }}
                        alt=""
                    />
                </Link>
            </div>
            <div className="flex items-center">
                <div className="flex items-center mr-4">
                    {/* <Link to="/dashboard-renew" className="800px:block hidden">
                        <Button>Renew</Button>
                    </Link> */}
                    {/* <Link
                        to="/dashboard/cupouns"
                        className="800px:block hidden"
                    >
                        <AiOutlineGift
                            color="#555"
                            size={30}
                            className="mx-5 cursor-pointer"
                        />
                    </Link> */}
                    {/* <Link to="/dashboard-events" className="800px:block hidden">
                        <MdOutlineLocalOffer
                            color="#555"
                            size={30}
                            className="mx-5 cursor-pointer"
                        />
                    </Link> */}
                    <Link
                        to="/dashboard-products"
                        className="800px:block hidden"
                    >
                        <FiShoppingBag
                            color="#555"
                            size={30}
                            className="mx-5 cursor-pointer"
                        />
                    </Link>
                    <Link to="/dashboard-orders" className="800px:block hidden">
                        <FiPackage
                            color="#555"
                            size={30}
                            className="mx-5 cursor-pointer"
                        />
                    </Link>
                    {/* <Link
                        to="/dashboard-messages"
                        className="800px:block hidden"
                    >
                        <BiMessageSquareDetail
                            color="#555"
                            size={30}
                            className="mx-5 cursor-pointer"
                        />
                    </Link> */}
                    <Link to={`/shop/${seller._id}`}>
                        <img
                            src={`${seller.avatar?.url}`}
                            alt=""
                            className="w-[50px] h-[50px] rounded-full object-cover"
                        />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default DashboardHeader;
